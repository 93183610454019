
.list-container {
  text-align: center;
  background-color: rgb(74, 22, 196);
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  
  
}

.scroller {
    height: 1.2em;
    line-height: 1.2em;
    position: relative;
    overflow: hidden;
    font-size: 40px;
    text-align: center;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    
  }

  .scroller > span {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: slide 4s infinite;
    font-weight: bold;
 
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;

    font-size: 50px;
    color: white !important;
    
  }

  @keyframes slide {
    0% {
      top: 0;
    }
    25% {
      top: -1.9em;
    }
    50% {
      top: -3.8em;
    }
    75% {
      top: -5.7em;
    }
    100% {
      top: 0;
    }
   
  }